import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sti-parameter-menu-separator',
  template: `
        <div class="stiJsViewerParametersMenuSeparator">
        </div>
  `
})

export class ParameterMenuSeparatorComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
